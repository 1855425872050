import { PLATFORM } from 'aurelia-pal';
import { Router, activationStrategy } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Messages, MESSAGE_STATUS } from 'services/messages';
import { DialogService } from 'aurelia-dialog';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import { WriteMessage } from './controls/write-message';
import { ROLE } from 'common/constants';
import { c } from 'common/common';
PLATFORM.moduleName('./controls/write-message');
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');

export class List {
    static inject = [Router, EventAggregator, Security, Messages, DialogService];
    _router;
    _ea;
    _security;
    _messages;
    _dialogService;

    status;
    messages;
    messageFilters = [{ value: '', keys: ['subject', 'from.fullName', 'from.email'] }];
    pageSize = 25;
    isMessageManager;
    selectAll = false;
    _handlers = [];

    constructor(router, ea, security, messages, dialogService) {
        this._router = router;
        this._ea = ea;
        this._security = security;
        this._messages = messages;
        this._dialogService = dialogService;

        this.isMessageManager = this._security.isInRole(ROLE.Admin);
    }

    determineActivationStrategy () {
		return activationStrategy.invokeLifecycle;
	}

    activate(model) {
        if (model.status) this.status = model.status;
        if (this._isAttached) {
            this._initialize();
        }
    }

    attached() {
        this._initialize();
        this._isAttached = true;
        this._handlers.push(this._ea.subscribe(c.EventKeys.messages.updated, () => {
            if (this._initializing) return;
            this._load();
        }));
    }

    toggleSelectAll() {
        this.selectAll = !this.selectAll;
        this.dgMessages.forEach(m => {
            m.checked = this.selectAll;
        });
    }

    async markAllRead(read) {
        try {
            const ids = [];
            this.dgMessages.forEach(m => {
                if (m.checked) ids.push(m.id);
            });
            await this._messages.massRead(ids, read);
            this.view(this.status);
        } catch (err) {
            console.log(err);
        }
    }

    async markAllArchived(archive) {
        try {
            const ids = [];
            this.dgMessages.forEach(m => {
                if (m.checked) ids.push(m.id);
            });
            await this._messages.massArchive(ids, archive);
            this.view(this.status);
        } catch (err) {
            console.log(err);
        }
    }

    toggleMessageChecked(ev, message) {
        ev.stopPropagation();
        message.checked = !message.checked;
        return false;
    }

    async _initialize() {
        this._initializing = true;
        if (!this.status) {
            this.status = MESSAGE_STATUS.current;
            await this._load();
        } else {
            this.view(this.status);
        }
        this._initializing = false;
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async _load() {
        try {
            if (this.status !== MESSAGE_STATUS.current) return;
            this.working = true;
            this.messages = await this._messages.current();
            this._messages.setNavList(this.messages);
        } catch (err) {
            this.messages = [];
            this._messages.clearNav();
        } finally {
            this.working = false;
        }
    }

    async view(status) {
        try {
            this.selectAll = false;
            this.status = status;
            this.messages = await this._messages.list(status);
            this._messages.setNavList(this.messages);
        } catch (err) {
            console.log(err);            
        }
    }

    viewMessage(message) {
        let route = `#/members/message-center/view/${message.id}?status=${this.status}`;
        this._router.navigate(route, true);
    }

    async replyToMessage(message) {
        try {
            const replyToMessage = await this._messages.get(message.id);
            const model = { replyToMessage };
	        this._dialogService.open({ viewModel: WriteMessage, model, ignoreTransitions: true }).whenClosed(response => {
	            if (response.wasCancelled) return;
                this.view(this.status);
	        });
        } catch (err) {
            console.log(err);
        }
    }

    async editMessage(message) {
        try {
            const editMessage = await this._messages.get(message.id);
            const model = { editMessage };
	        this._dialogService.open({ viewModel: WriteMessage, model, ignoreTransitions: true }).whenClosed(response => {
	            if (response.wasCancelled) return;
                this.view(this.status);
	        });
        } catch (err) {
            console.log(err);
        }
    }

    async markArchived(message, archive) {
        try {
            await this._messages.archive(message.id, archive);
            this.view(this.status);
        } catch (err) {
            console.log(err);
        }
    }

    async markRead(message, read) {
        try {
            await this._messages.read(message.id, read);
            this.view(this.status);
        } catch (err) {
            console.log(err);
        }
    }

    async deleteMessage(message) {
        const model = { key: 'delete-message', okButtonClass: 'btn-danger', messageObject: { title: message.subject } };
	    this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
            try {
                await this._messages.delete(message.id);
                this.view(this.status);
            } catch (err) {
                console.log(err);
            }
        });
    }
}
