import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { WriteMessage } from './controls/write-message';
import { RouterEvent } from 'aurelia-router';
import { MESSAGE_STATUS } from 'services/messages';
PLATFORM.moduleName('./controls/write-message');

export class Index {
    static inject = [EventAggregator, DialogService];
    _router;
    _ea;
    _dialogService;
    MESSAGE_STATUS = MESSAGE_STATUS;
    status;

    _handlers = [];

    constructor(ea, dialogService) {
        this._ea = ea;
        this._dialogService = dialogService;
    }

    configureRouter(config, router) {
        config.map([
            {
                route: ['', '/:status'],
                moduleId: PLATFORM.moduleName('members/message-center/list'),
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: false },
            },
            {
                route: ['/view/:id', '/view/new'],
                moduleId: PLATFORM.moduleName('members/message-center/view'),
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: false },
            },
        ]);

        this._router = router;
    }

    activate() {
        this._handlers.push(this._ea.subscribe(RouterEvent.Complete, (event) => {
            if (this._router.currentInstruction) {
                if (this._router.currentInstruction.params && this._router.currentInstruction.params.status) {
                    this.status = this._router.currentInstruction.params.status;
                } else if (this._router.currentInstruction.queryParams && this._router.currentInstruction.queryParams.status) {
                    this.status = this._router.currentInstruction.queryParams.status;
                }
            }
            if (!this.status) this.status = MESSAGE_STATUS.current;
        }));
    }

    deactivate() {
        this._handlers.forEach(h => h.dispose());
    }

    view(status) {
        this._router.navigate(`#/members/message-center/${encodeURIComponent(status)}`, true);
    }

    createMessage() {
        const model = {};
	    this._dialogService.open({ viewModel: WriteMessage, model, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;
	    });
    }
}
