import { bindable } from 'aurelia-framework';

export class MessageView {
    static inject = [Element];
    _element;

    @bindable message;
    @bindable open = false;

    constructor(element) {
        this._element = element;
    }

    reload(ev) {
        this._element.dispatchEvent(new CustomEvent('reload', { bubbles: true, detail: ev }));
    }

    deleted(ev) {
        this._element.dispatchEvent(new CustomEvent('deleted', { bubbles: true, detail: ev }));
    }
}
