import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { c } from 'common/common';

export class Index {
    static inject = [EventAggregator];
    _ea;
    current = 'agents';

    _handlers = [];

    constructor(ea) {
        this._ea = ea;
    }

    configureRouter(config, router){
        config.title = 'LPFN';
        config.auth = true;
        config.map([
                {
                    route: ['', 'agents'],
                    moduleId: PLATFORM.moduleName('members/lpfn/agents'),
                    nav: false,
                    membersOnly: true,
                    adminOnly: false,
                    settings: { title: 'LPFN Hierarchy', description: 'Search for any agent within the hierarchy', canAddToMobileMenu: false },
                },
                {
                    route: 'reports',
                    moduleId: PLATFORM.moduleName('members/lpfn/reports'),
                    nav: false,
                    membersOnly: true,
                    adminOnly: false,
                    settings: { title: 'LPFN Reports', description: 'View bonus reports for LPFN', canAddToMobileMenu: false },
                },
        ]);
        this.router = router;
    }

    attached() {
        this._setPageTitle();
        this._handlers.push(this._ea.subscribe(c.EventKeys.navigation.changed, () => this._setPageTitle()));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    _setPageTitle() {
        try {
            const title = this.router.currentInstruction.config.settings.title;
            const description = this.router.currentInstruction.config.settings.description;
            window.setTimeout(() => this._ea.publish(c.EventKeys.site.setPageTitle, { title, description }), 0); // must be after the navigation event
        } catch (err) {
            console.log(err);
        }
    }
}
