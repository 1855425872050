import { PLATFORM } from 'aurelia-pal';
import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Messages } from 'services/messages';
import { DialogService } from 'aurelia-dialog';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import { WriteMessage } from './write-message';
import { ROLE } from 'common/constants';
PLATFORM.moduleName('./write-message');
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');

export class MessageSummary {
    static inject = [Element, EventAggregator, Security, Messages, DialogService];
    _element;
    _ea;
    security;
    _messages;
    _dialogService;

    @bindable message;
    @bindable open = false;

    isMessageManager;
    showFullMessage = false;

    constructor(element, ea, security, messages, dialogService) {
        this._element = element;
        this._ea = ea;
        this.security = security;
        this._messages = messages;
        this._dialogService = dialogService;

        this.isMessageManager = this.security.isInRole(ROLE.Admin);
    }

    attached() {
        if (this.open) this.toggleFullMessage();
    }

    toggleFullMessage() {
        if (this.buttonClicked) {
            this.buttonClicked = false;
            return;
        }
        this.showFullMessage = !this.showFullMessage;
    }

    async markArchived(archive) {
        this.buttonClicked = true;
        try {
            await this._messages.archive(this.message.id, archive);
            this.message.isArchived = archive;
        } catch (err) {
            console.log(err);
        }
    }

    async markRead(read) {
        this.buttonClicked = true;
        try {
            await this._messages.read(this.message.id, read);
            this.message.isRead = read;
        } catch (err) {
            console.log(err);
        }
    }

    _fireEvent(eventKey) {
        this._element.dispatchEvent(new CustomEvent(eventKey, { bubbles: true, detail: { id: this.message.id } }));
    }

    replyToMessage() {
        this.buttonClicked = true;
        const model = { replyToMessage: this.message };
	    this._dialogService.open({ viewModel: WriteMessage, model, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;
            this._fireEvent('reload');
	    });
    }

    edit() {
        const model = { editMessage: this.message };
	    this._dialogService.open({ viewModel: WriteMessage, model, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;
            this._fireEvent('reload');
	    });
    }

    async delete() {
        this.buttonClicked = true;
        const model = { key: 'delete-message', okButtonClass: 'btn-danger', messageObject: { title: this.message.subject } };
	    this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
            try {
                await this._messages.delete(this.message.id);
                this._fireEvent('deleted');
            } catch (err) {
                console.log(err);
            }
        });
    }
}
