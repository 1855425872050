import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Menus } from 'services/menus';
import { RouterConfigBase } from 'resources/base-classes/router-config-base';

export class Index extends RouterConfigBase {
	static inject = [EventAggregator, Security, Menus];

	constructor(ea, security, menus) {
        super(ea, security, menus, undefined, false);
	}

	configureRouter(config, router){
		config.map([
            {
				route: 'team',
				moduleId: PLATFORM.moduleName('members/mentors/team'),
				nav: false,
				membersOnly: true,
				adminOnly: false,
                settings: { title: 'mentor-team', description: 'mentor-team-description', canAddToMobileMenu: true, icon: 'fa-duotone fa-lightbulb-exclamation-on' }
			},
		]);

		this.router = router;
	}

	attached() {
        this._attached();
    }

    detached() {
        this._detached();
    }
}
