import {inject} from 'aurelia-framework';
import {Page} from 'common/ui';

@inject(Page)
export class AdvertisingSubmission {

	zypdc890gixa13 = null;

	constructor(page) {
		this.page = page;
	}

	activate(params, routerConfig) {
		this.page.track('members/marketing/advertising-submission');
	}

	attached() {
		this.loadWufoo()
	}

	loadWufoo() {
		var self = this;
		var s = document.createElement('script'), options = {
			'userName':'wealthincomeplanner',
			'formHash':'zypdc890gixa13',
			'autoResize':true,
			'height':'800',
			'async':true,
			'host':'wufoo.com',
			'header':'hide',
			'ssl': true
		};
		s.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'www.wufoo.com/scripts/embed/form.js';
		s.onload = s.onreadystatechange = function() {
			var rs = this.readyState; if (rs) if (rs != 'complete') if (rs != 'loaded') return;
			try {
				self.zypdc890gixa13 = new WufooForm();
				self.zypdc890gixa13.initialize(options);
				self.zypdc890gixa13.display();
			} catch (e) { }
		};
		var scr = document.getElementsByTagName('script')[0], par = scr.parentNode;
		par.insertBefore(s, scr);
	}
}