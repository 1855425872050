import {inject} from 'aurelia-framework';
import {Page} from 'common/ui';

@inject(Page)
export class MyBranding {

	constructor(page) {
		this.page = page;
	}

	activate() {
	    this.page.track('members/my-branding');
	}

	attached() {
	    $("#my-branding-business-card-video-popup-dialog")
			.on("shown.bs.modal", this.startVideo)
			.on("hidden.bs.modal", this.stopVideo);
	}

	startVideo() {
	    $("#my-branding-business-card-video").attr("src", "https://player.vimeo.com/video/133831291?autoplay=1");
	}
	stopVideo() {
	    $("#my-branding-business-card-video").attr("src", "");
	}

}