import { Api } from 'common/server';
import { Page } from 'common/ui';
import moment from 'moment';

export class AgencyReports {
    static inject = [Api, Page];
    _api;
    _page;

    reports = [];

    reportDate = moment();
    dpOptions = { orientation: "bottom left", autoclose: true };

    constructor(api, page) {
        this._api = api;
        this._page = page;

        this._initialize();
    }

    _initialize() {
        this.reports.push({ name: 'Simplified Submit Monthly Bonus', api: 'report/agent-monthly-bonus-export', working: false, useDate: true });
        this.reports.push({ name: 'Agency Monthly Bonus', api: 'report/agency-monthly-bonus-export', working: false, useDate: true });
    }

    async exportReport(report) {
        try {
            const model = report.useDate
                ? { date: this.reportDate.format('MM/DD/YYYY') }
                : undefined;
            report.working = true;
            await this._page.export(this._api, report.api, model);
        } catch (err) {
            console.log(err);
        } finally {
            report.working = false;
        }
    }
}
