import { Router, activationStrategy } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Messages, MESSAGE_STATUS } from 'services/messages';

export class View {
    static inject = [Router, EventAggregator, Messages];
    _router;
    _ea;
    _messages;
    MESSAGE_STATUS = MESSAGE_STATUS;

    id;
    message;
    status = MESSAGE_STATUS.current;

    _handlers = [];

    constructor(router, ea, messages) {
        this._router = router;
        this._ea = ea;
        this._messages = messages;
    }

    determineActivationStrategy () {
		return activationStrategy.invokeLifecycle;
	}

    activate(model) {
        if (model) {
            this.id = model.id;
            this.status = model.status || MESSAGE_STATUS.current;
        }
        this.load();
    }

    deactivate() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async load() {
        try {
            if (!this.id) return;
            this.working = true;
            await this._messages.read(this.id, true);
            this.message = await this._messages.get(this.id);
            if (this.edit) this.editMessage = this.message;
            this.nav = this._messages.getNav(this.id);
        } catch (err) {
            this.message = undefined;
        } finally {
            this.working = false;
        }
    }

    deleted() {
        this._router.navigate(`#/members/message-center/${encodeURIComponent(this.status)}`, true);
    }
}
