import { computedFrom } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Security } from 'common/security';
import { Messages } from 'services/messages';
import { Members } from 'services/members';
import { Levels } from 'services/levels';
import { I18n } from 'common/i18n';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { ROLE } from 'common/constants';
import moment from 'moment';

export class WriteMessage {
    static inject = [DialogController, Security, Messages, Members, Levels, I18n, NewInstance.of(ValidationController)];
    dialogController;
    _security;
    _messages;
    _members;
    _i18n;
    _levels;

    messageTitle;
    replyToMessage;
    editMessage;

    canAddNotifications = false;
    toMemberAndDownline;
    id;
    messageType;
    toMember;
    subject;
    body;
    validationController;
    focusMember = true;

    constructor(dialogController, security, messages, members, levels, i18n, validationController) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this._security = security;
        this._messages = messages;
        this._members = members;
        this._levels = levels;
        this._i18n = i18n;

        this.canAddNotifications = this._security.isInRole(ROLE.Admin);
        if (!this.canAddNotifications) this.messageType = 'personal';

        this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('toMemberAndDownline').required().when(x => x.messageType === 'notification' && x.filterBy === 'agent-and-downline')
            .ensure('toMember').required().when(x => x.messageType === 'personal')
            .ensure('subject').required().when(x => !x.replyToMessage || x.messageType === 'notification')
            .ensure('body').required()
            .on(this);
    }

    async activate(model) {
        console.log(model);
        if (model.replyToMessage) {
            this.messageTitle = 'message-reply-title';
            this.focusMessage = false;
            this.focusMember = false;
            this.replyToMessage = model.replyToMessage;
            this.messageType = model.replyToMessage.type;
            if (this.replyToMessage) {
                this.toMember = model.replyToMessage.from;
                this.focusMessage = true;
            } else {
                this.focusMember = true;
            }
        } else if (model.editMessage) {
            this.messageTitle = 'message-edit-title';
            this.id = model.editMessage.id;
            await this.selectMessageType(model.editMessage.type);
            let filter = 'all';
            this.level = undefined;
            this.levelOrAbove = false;
            this.toMemberAndDownline = undefined;
            if (model.editMessage.toFilter) {
                if (model.editMessage.toFilter.memberIdAndDownline) {
                    console.log('member and downline', model.editMessage.toFilter.memberIdAndDownline);
                    filter = 'agent-and-downline';
                    this.toMemberAndDownline = await this._members.getMember(model.editMessage.toFilter.memberIdAndDownline);
                } else if (model.editMessage.toFilter.level) {
                    filter = model.editMessage.toFilter.levelTypeValue;
                    this.level = model.editMessage.toFilter.level;
                    this.levelOrAbove = model.editMessage.toFilter.levelOrAbove;
                }
            }
            this.selectNotificationFilter(filter);
            this.subject = model.editMessage.subject;
            this.body = model.editMessage.content;
            this.startDate = model.editMessage.startDate;
            this.endDate = model.editMessage.endDate;
        } else {
            this.messageTitle = 'message-new-title';
        }
    }

    async selectMessageType(messageType) {
        this.messageType = messageType;
        if (this.messageType === 'notification') {
            await this._loadNotificationOptions();
        }
    }

    selectNotificationFilter(filter) {
        this.filterBy = filter;
        this.displayLevelNotificationOption = false;
        this.displayMemberSelector = false;
        this.levelNameKey = undefined;
        this.levels = [];
        switch (filter) {
            case 'all':
                break;
            case 'agent-and-downline':
                this.displayMemberSelector = true;
                break;
            case 'Life':
                this.displayLevelNotificationOption = true;
                this.levelNameKey = 'life-level';
                this.levels = this._levelData.lifeLevels;
                break;
            case 'Annuity':
                this.displayLevelNotificationOption = true;
                this.levelNameKey = 'annuity-level';
                this.levels = this._levelData.annuityLevels;
                break;
            case 'HMA':
                this.displayLevelNotificationOption = true;
                this.levelNameKey = 'annuity-level';
                this.levels = this._levelData.hmaLevels;
                break;
        }
    }

    async _loadNotificationOptions() {
        try {
            this._levelData = await this._levels.byType();
        } catch (err) {
            console.log(err);
        }
    }

    @computedFrom('messageType', 'filterBy')
    get canWriteMessage() {
        if (!this.messageType) return false;
        if (this.messageType === 'personal') return true;
        return this.filterBy;
    }

    @computedFrom('messageType')
    get saveButtonTitle() {
        if (this.messageType === 'personal') return this._i18n.tr('send-message');
        return this._i18n.tr('save');
    }

    async sendMessage() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            const startDate = this.startDate ? moment.parseZone(this.startDate).format('MM/DD/YYYY') : null;
            const endDate = this.endDate ? moment.parseZone(this.endDate).format('MM/DD/YYYY') : null;
            const toMemberId = this.toMember ? this.toMember.id : null;
            const replyToMessageId = this.replyToMessage ? this.replyToMessage.id : null;
            const memberIdAndDownline = this.toMemberAndDownline ? this.toMemberAndDownline.id : null;
            if (this.id) {
                const editResponse = await this._messages.edit(this.id, this.messageType, this.subject, this.body, startDate, endDate, this.filterBy, this.level, this.levelOrAbove, memberIdAndDownline);
                this.close(editResponse.id);
            } else {
                const response = await this._messages.create(this.messageType, this.subject, this.body, toMemberId, replyToMessageId, startDate, endDate, this.filterBy, this.level, this.levelOrAbove, memberIdAndDownline);
                this.close(response.id);
            }
        } catch (err) {
            console.log(err);
        }     
    }

    close(id) {
        if (!id) {
            this.toMember = undefined;
            this.subject = undefined;
            this.body = undefined;
        }
        this.dialogController.ok();
    }
}
