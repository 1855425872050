import { bindable } from 'aurelia-framework';

export class MessageReply {
    static inject = [Element];

    @bindable message;

    constructor(element) {
        this.element = element;
    }

    loadChildren() {
        this.node.toggleNode();
    }
}
