import { Mentors } from 'services/mentors';

export class Team {
    static inject = [Mentors];

    constructor(mentors) {
        this._mentors = mentors;
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.mentees = await this._mentors.mentees();
        } catch (err) {
            console.log(err);
        }
    }
}
